<template>
  <div id="left-menu">
    <div class="sub-header">
      <img :src="subHeaderUrl" alt="" />
    </div>
    <div class="left-menu">
      <ul>
        <template v-for="(subMenu, key, index) in menuList">
          <li
            :key="key"
            :class="activeList[index]"
            @mouseover="activeChange(index)"
            @mouseleave="activeReverse()"
          >
            <div class="link-wrap">
              <router-link :to="subMenu.url" class="link">
                <div class="list">
                  <div class="icon"></div>
                  <div class="title">{{ subMenu.title }}</div>
                </div>
              </router-link>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-menu',
  props: ['sub_title', 'c_title'],
  data() {
    return {
      activeList: [],
      current_menu: '',
      menu: {
        company: {
          about: {
            url: '/company/about',
            title: '회사소개'
          },
          ceo: {
            url: '/company/ceo',
            title: 'CEO 인사말'
          },
          history: {
            url: '/company/history',
            title: '회사연혁'
          },
          vision: {
            url: '/company/vision',
            title: '회사비젼'
          },
          orga: {
            url: '/company/orga',
            title: '조직도'
          },
          ci: {
            url: '/company/ci',
            title: 'CI 소개'
          },
          map: {
            url: '/company/map',
            title: '찾아오시는 길'
          }
        },
        business: {
          connection: {
            url: '/business/connection',
            title: '주요 사업'
          },
          sale: {
            url: '/business/sale',
            title: '투자 사업'
          }
        },
        product: {
          saving: {
            url: '/product/saving',
            title: '국제전화 선불카드'
          }
        },
        customer: {
          online: {
            url: '/customer/online',
            title: '온라인 문의'
          },
          dealer: {
            url: '/customer/dealer',
            title: '대리점 문의'
          }
        },
        info: {
          sitemap: {
            url: '/info/sitemap',
            title: '사이트맵'
          }
        }
      }
    }
  },
  methods: {
    activeChange(index) {
      const self = this
      let i = 0
      this.activeList = []
      Object.keys(this.menuList).forEach(() => {
        if (i == index) {
          self.activeList.push('active')
        } else {
          self.activeList.push('')
        }
        i++
      })
      self.$forceUpdate()
    },
    activeReverse() {
      const self = this
      this.activeList = []
      Object.keys(this.menuList).forEach(key => {
        if (key == this.c_title) {
          self.activeList.push('active')
        } else {
          self.activeList.push('')
        }
      })
      self.$forceUpdate()
    }
  },
  computed: {
    subHeaderUrl: function() {
      switch (this.sub_title) {
        case 'business':
          return '/images/business_text.png'
        case 'product':
          return '/images/product_text.png'
        case 'customer':
          return '/images/customer_text.png'
        case 'info':
          return '/images/information_text.png'
        default:
          return '/images/company_text.png'
      }
    },
    menuList: function() {
      return this.menu[this.sub_title]
    },
    currntMenu: function() {
      return this.c_title
    }
  },
  watch: {
    currntMenu: function() {
      this.activeReverse()
    }
  }
}
</script>

<style scoped>
#left-menu {
  width: 195px;
}
#left-menu .sub-header {
  height: 115px;
  background: rgb(1, 28, 61);
  background: -moz-linear-gradient(
    180deg,
    rgba(1, 28, 61, 0.3) 0%,
    rgba(184, 189, 196, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(1, 28, 61, 0.3) 0%,
    rgba(184, 189, 196, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(1, 28, 61, 0.3) 0%,
    rgba(184, 189, 196, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#011c3d",endColorstr="#b8bdc4",GradientType=1);
}
#left-menu .left-menu {
  height: 400px;
  padding-top: 40px;
  box-sizing: border-box;
  background: rgb(198, 202, 205);
  background: -moz-linear-gradient(
    180deg,
    rgba(198, 202, 205, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(198, 202, 205, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(198, 202, 205, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c6cacd",endColorstr="#ffffff",GradientType=1);
}
#left-menu .left-menu ul {
  padding-left: 14px;
}
#left-menu .left-menu ul li {
  padding-top: 4px;
  font-size: 12px;
}
#left-menu .left-menu ul li div.link-wrap {
  padding-left: 8px;
  padding-right: 20px;
  -webkit-transition: background-color 0.5s, transform 0.5s;
  transition: background-color 0.5s, transform 0.5s;
}
#left-menu .left-menu ul li.active div.link-wrap {
  background-color: #2164ab;
}
#left-menu .left-menu li div.list {
  padding: 6px 20px 6px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  -webkit-transition: color 0.5s, transform 0.5s;
  transition: color 0.5s, transform 0.5s;
}
#left-menu .left-menu ul li.active div.list {
  color: #fff;
  border-bottom: none;
  padding-bottom: 7px;
}
#left-menu .left-menu .list .icon {
  width: 13px;
  height: 12px;
  display: block;
  background-image: url('/images/side_off.png');
  margin-right: 10px;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  -webkit-transition: background-image 0.5s, transform 0.5s;
  transition: background-image 0.5s, transform 0.5s;
}
#left-menu .left-menu ul li.active div.list .icon {
  background-image: url('/images/side_on.png');
}
</style>
