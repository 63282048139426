<template>
  <div id="main">
    <sub-header :data="sub_title"></sub-header>
    <div class="main-content">
      <left-menu :sub_title="sub_title" :c_title="c_title"></left-menu>
      <router-view id="content"></router-view>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/MainSubHeader.vue'
import LeftMenu from '@/components/LeftMenu.vue'

export default {
  name: 'MainLayout',
  components: {
    'sub-header': SubHeader,
    'left-menu': LeftMenu
  },
  data() {
    return {
      sub_title: '',
      c_title: ''
    }
  },
  mounted() {
    const uri = this.$route.path.split('/')
    this.sub_title = uri[1]
    this.c_title = uri[2]
  },
  updated() {
    const uri = this.$route.path.split('/')
    this.sub_title = uri[1]
    this.c_title = uri[2]
  }
}
</script>

<style scoped>
#main div.main-content {
  margin: 0 auto;
  width: 1000px;
  position: relative;
  display: flex;
}
#content {
  padding-top: 100px;
}
</style>
