<template>
  <div id="sub-header" :style="backgroundImage"></div>
</template>

<script>
export default {
  name: 'MainSubHeader',
  props: ['data'],
  computed: {
    backgroundImage: function() {
      let backgroundImg
      switch (this.data) {
        case 'business':
          backgroundImg = "background-image: url('/images/sub_business.jpg')"
          break
        case 'product':
          backgroundImg = "background-image: url('/images/sub_product.jpg')"
          break
        case 'customer':
          backgroundImg = "background-image: url('/images/sub_customer.jpg')"
          break
        case 'info':
          backgroundImg = "background-image: url('/images/sub_information.jpg')"
          break
        default:
          backgroundImg = "background-image: url('/images/sub_company.jpg')"
          break
      }
      return backgroundImg
    }
  }
}
</script>

<style scoped>
#sub-header {
  position: absolute;
  width: 100%;
  height: 115px;
  min-width: 1000px;
  background-position: center center;
}
</style>
